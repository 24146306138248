@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* COLORS */
/* orange */
/* background-color: #FB9A28; */
/* background-color: rgba(255, 160, 17, 0.6); */
/* green */
/* background-color: #27BC9E; */
/* background-color: rgba(39, 188, 158, 0.6); */
/* blue */
/* background-color: #3488CF; */
/* background-color: rgba(39, 188, 158, 0.6); */

#root {
  font-family: 'Open Sans', sans-serif;
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Baloo', cursive !important;
}

.font-borrowit {
  font-family: 'Marck Script', cursive !important;
  font-weight: bold;
}

.header-logo {
  font-size: 1.75rem;
  color: white;
}

.header {
  background-color: rgba(255, 160, 17, 1);
}

.emoji-image {
  font-size: 5rem;
}

.mb-minus-1 {
  margin-bottom: -1rem !important;
}

/* hide the arrow on the navbar menu */
/* .dropdown-toggle::after {
  display: none !important;
} */

/* Large devices (desktops, 992px and up) */
@media (min-width: 576px) {
  .header-notification-feed {
    transform: translate3d(-390px, 38px, 0px) !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 992px) {
  .header-notification-feed {
    transform: translate3d(-330px, 38px, 0px) !important;
  }
}

.feed-row {
  flex-wrap: nowrap !important;
}

.footer {
  background-color: rgba(255, 160, 17, 1);
  color: white;
}

.btn-secondary {
  color: white !important;
}

.btn-outline-secondary:hover {
  color: white !important;
}

.btn-info {
  border-color: #ced4da !important;
}

/* mobile hover fix for the filter button on explore page */
.btn-info:hover.nohover:hover {
  background-color: white !important;
}

/* mobile hover fixes for category and search suggestion buttons on explore page */
.btn-outline-secondary:hover.nohover:hover {
  background-color: white !important;
  color: #fb9a28 !important;
}

.header-link {
  font-family: 'Baloo';
  font-size: 1.25rem;
  color: white;
}

/**
* Start page
*/

.landing-container {
  padding: 0px !important;
  z-index: 10;
  background-color: rgba(255, 160, 17, 0.1);
}

.landing-left {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.landing-left h1 {
  font-size: 2.5rem;
  margin-top: 12px;
  margin-bottom: 50px;
  color: #fb9a28;
}

.btn-landing {
  padding: 0.5rem 2rem 0.5rem 2rem !important;
}

/**
* App
*/

.modal-image {
  min-width: 100%;
  height: 170px;
  background-image: url('/lidya-nada-636142-unsplash.jpg');
  background-size: cover;
  background-position: center top 33%;
  background-repeat: no-repeat;
  overflow: hidden;
}

.borrowit-card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0rem !important;
}

.borrowit-card-body {
  padding: 0px !important;
}

.outloaned {
  background-color: #e9ecef !important;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #fb9a28;
  background: linear-gradient(#fb9a28 0%, #fb9a28 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}
.ribbon span::before {
  content: '';
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #fb9a28;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #fb9a28;
}
.ribbon span::after {
  content: '';
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #fb9a28;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #fb9a28;
}
.ribbon-green span {
  background: #27bc9e !important;
  background: linear-gradient(#27bc9e 0%, #27bc9e 100%) !important;
}
.ribbon-green span::before {
  border-left: 3px solid #27bc9e;
  border-top: 3px solid #27bc9e;
}
.ribbon-green span::after {
  border-right: 3px solid #27bc9e;
  border-top: 3px solid #27bc9e;
}
.ribbon-blue span {
  background: #3488cf !important;
  background: linear-gradient(#3488cf 0%, #3488cf 100%) !important;
}
.ribbon-blue span::before {
  border-left: 3px solid #3488cf;
  border-top: 3px solid #3488cf;
}
.ribbon-blue span::after {
  border-right: 3px solid #3488cf;
  border-top: 3px solid #3488cf;
}

/* On mouse-over, add a deeper shadow */
/* .card:hover {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
} */

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #737373;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.lds-heart {
  margin: auto;
  /* display: inline-block; */
  position: relative;
  width: 64px;
  height: 64px;
  transform: rotate(45deg);
  transform-origin: 32px 32px;
}
.lds-heart div {
  top: 23px;
  left: 19px;
  position: absolute;
  width: 26px;
  height: 26px;
  background: #27bc9e;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: ' ';
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  background: #27bc9e;
}
.lds-heart div:before {
  left: -17px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -17px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

.app-outer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  position: absolute;

  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 0;
}

.app-inner {
  display: flex;
  height: 100%;
  width: 100%;
}

.tag-cloud {
  flex: 1;
}

.tag-cloud > div {
  transition: 1.4s;
}

.tag-item-wrapper {
  cursor: pointer;
}

.tag-item-wrapper:hover {
  z-index: 0;
}

.tag-item-wrapper:hover .tag-item-tooltip {
  display: block;
}

.tag-item-tooltip {
  background: #c01916;
  color: white;
  margin-top: 5px;
  z-index: 100;
  display: none;
  padding: 15px;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  min-width: 60px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.dropzone {
  width: 100%;
  border: 2px dashed #27bc9e;
  border-radius: 0.25rem;
  background-color: rgba(39, 188, 158, 0.1);
  padding: 0.375rem 0.75rem;
  text-align: center;
}

.dropzone-intro {
  color: black;
}

.dropzone-action {
  color: #27bc9e;
}

.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
  color: white;
  background-color: #fb9a28;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dropdown-menu-right {
  right: 0px !important;
}

.border-active {
  border: 2px solid #27bc9e !important;
}